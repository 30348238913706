export default {
  "general": {
    "language_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
    "backTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to"])},
    "backToAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "clone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clone"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
    "mandatoryFieldsMustBeFilledIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The marked mandatory fields must be filled out."])},
    "cannotSaveWithoutChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No changes have been made."])}
  },
  "sidebar": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show menu"])},
    "library": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
      "createEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create entry"])},
      "setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup"])}
    },
    "administration": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])}
    },
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licenses"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information, contract and help"])}
  },
  "worklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
  "loadWorklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load applications"])},
  "emptyWorklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No application found"])},
  "deleteApplicationDocument": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete application"])},
    "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to delete the application '", _interpolate(_list(0)), "'?"])},
    "inactive": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application cannot be deleted as it is marked as non-deletable"])}
    }
  },
  "coverSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cover Sheet"])},
  "editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor of Applications"])},
  "referenceNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File number"])},
  "clientSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicants reference no."])},
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant"])},
  "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation date"])},
  "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["second"])},
  "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
  "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minute"])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
  "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hour"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["week"])},
  "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weeks"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
  "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["months"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
  "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years"])},
  "ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ago"])},
  "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created"])},
  "datePreposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])},
  "timePreposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])},
  "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "locales": {
    "de-DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "en-US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
  },
  "applicationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of application"])},
  "applicationTypes": {
    "PATENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patent"])},
    "PATENT_APPLICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patent application"])},
    "UTILITY_MODEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utility model"])}
  },
  "applicationVariant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variant"])},
  "applicationVariants": {
    "FULL_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full application"])},
    "CORE_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Core application"])}
  },
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "patentHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant"])},
  "patentHolderAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant's address"])},
  "isDeletable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application cannot be deleted"])},
  "genericTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic Term"])},
  "genericTermPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term"])},
  "genericTermComplementTextPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional text"])},
  "mainFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main figure"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "descriptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description text"])},
  "figure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figure"])},
  "figureEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figure Editor"])},
  "figure.abbreviated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fig."])},
  "createNewFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new figure"])},
  "uploadFigureBaseImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload new figure"])},
  "searchApplicationFigures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search figures"])},
  "setAsMainFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set as main figure"])},
  "currentMainFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current main figure"])},
  "editFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit figure in figure editor"])},
  "showFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show figure full size"])},
  "uploadFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload new image for figure"])},
  "downloadFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download figure"])},
  "figureRendering.notUpToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The figure is outdated! An updated version is currently being created."])},
  "figureRendering.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured during creation of the figure!"])},
  "orientation.landscape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landscape"])},
  "orientation.portrait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portrait"])},
  "newFigure.landscape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For optimal image quality, please provide the image file as a black and white drawing in DIN A4 format with a border of 2.5 cm and a resolution of 300 DPI (corresponds to a resolution of 3508 pixels  2480 pixels [W/H])"])},
  "newFigure.portrait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For optimal image quality, please provide the image file as a black and white drawing in DIN A4 format with a border of 2.5 cm and a resolution of 300 DPI (corresponds to a resolution of 2480 Pixel  3508 pixel [W/H])"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "coverSheetCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Application"])},
  "coverSheetEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Application"])},
  "coverSheetClone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clone Application"])},
  "textEditorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text editor"])},
  "textBlocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document structure"])},
  "figures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figures"])},
  "referenceNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference numbers"])},
  "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbols"])},
  "helpLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helper lines"])},
  "noDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No description"])},
  "createReferenceSigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create reference numbers"])},
  "applyReferenceSigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply reference numbers to the application text"])},
  "hideReferenceSigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide reference numbers"])},
  "showReferenceSigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show reference numbers"])},
  "configureApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply configuration to text"])},
  "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
  "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term"])},
  "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
  "stemForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stem of the word"])},
  "searchStemForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search stem of the word"])},
  "searchStemRefresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Word Stems"])},
  "searchStemFormTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word stems for which the reference number in the application text is to be assigned."])},
  "excludeStemFormMatches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusive"])},
  "excludeStemFormMatchesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms that should not be matched as word hits."])},
  "stemFormMatches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hits"])},
  "stemFormMatchesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word hits currently associated with the reference number."])},
  "deletedClaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Deleted claim)"])},
  "APPLICATION_ABSTRACT": {
    "textTooLong": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The summary exceeds the maximum length of ", _interpolate(_list(1)), " with ", _interpolate(_list(0)), " characters."])}
  },
  "deleteReferenceSign": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete reference number"])},
    "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to delete the reference number '", _interpolate(_list(0)), "'?"])}
  },
  "deleteApplicationFigure": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete figure"])},
    "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to delete the figure 'Fig. ", _interpolate(_list(0)), "'?"])}
  },
  "ambigiousReferenceSignsHits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reference number has word hits that could not be clearly assigned."])},
  "searchIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search in library"])},
  "searchMainTabs": {
    "APPLICATION_DOCUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
    "LIBRARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])}
  },
  "searchApplicationDocuments": {
    "MAIN_CLAIM_AND_EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main claim & explanation"])},
    "ALL_FIGURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All figures"])}
  },
  "searchSpace": {
    "TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
    "TEXT_BLOCK_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic Term General"])},
    "TEXT_BLOCK_B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic Term Figures"])},
    "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
  },
  "searchSpaceSingular": {
    "TERM": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term Definition"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Term Definition"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Term Definition"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Term Definition"])}
    },
    "TEXT_BLOCK_A": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic Term General"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Generic Term General"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Generic Term General"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Generic Term General"])}
    },
    "TEXT_BLOCK_B": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic Term Figure"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Generic Term Figure"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Generic Term Figure"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Generic Term Figure"])}
    },
    "OTHER": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Other"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Other"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Other"])}
    }
  },
  "searchLocale": {
    "de-DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "en-US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
  },
  "toSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "deleteAllSearchTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all Search Terms"])},
  "createEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create"])},
  "createLibraryEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create entry in library"])},
  "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords"])},
  "keywordsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords under which the library entry should be found via the search."])},
  "newEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword"])},
  "libraryEntryCreatedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Library entry '", _interpolate(_list(0)), "' created successfully."])},
  "brickEntryCreatedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Library entry '", _interpolate(_list(0)), "' created successfully."])},
  "download": {
    "docx": {
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download application as *.docx file"])},
      "withFigures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A *.docx file with text and figures"])},
      "withoutFigures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text and figures in separate *.docx files"])}
    }
  },
  "exportErrorDialog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figures not up-to-date"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the application not all changed figures have been updated in the background. When exporting, any existing figures that are not updated are replaced by a placeholder. Please check the figures in the exported document for completeness and, if necessary, repeat the export at a later point in time"])},
    "optionForce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
  },
  "canvas": {
    "noFigureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Figure"])}
  },
  "menubar": {
    "nodes": {
      "inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inline"])},
      "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paragraph"])}
    },
    "tooltip": {
      "figure": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Figure ", _interpolate(_list(0))])},
      "referenceSign": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Reference number ", _interpolate(_list(0))])},
      "undo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Undo '", _interpolate(_list(0)), "'"])},
      "undoList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show last actions"])},
      "redo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Redo '", _interpolate(_list(0)), "'"])},
      "redoList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show redo list"])},
      "localChanges": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " changed"])},
      "localChangesUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
      "remoteChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved change"])},
      "generateAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate all and apply reference numbers"])},
      "inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore paragraph"])},
      "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo ignore paragraph"])},
      "unSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The text block could not be saved. Please check your internet connection!\n\n The next time you make a change in the document, we try to save the text block again.\n If saving still fails please save your changes externally."])}
    },
    "aiTextTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI text"])},
    "aiTextHover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply AI text"])},
    "applyForBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply for block"])},
    "applyForDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply for application"])},
    "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
    "autoGenerator": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Generate ", _interpolate(_list(0))])},
    "generateAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate all"])},
    "create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Add ", _interpolate(_list(0))])},
    "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Delete ", _interpolate(_list(0))])},
    "pasteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste text"])},
    "underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["underline"])},
    "createCurve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draw bezier-line"])},
    "createDashedCurve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draw dashed bezier-line"])},
    "search": {
      "placeholder": {
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
        "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace..."])}
      },
      "find": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search in application text"])}
      },
      "clear": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear search"])}
      },
      "replace": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace first hit"])}
      },
      "replaceAll": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace all"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace all hits"])}
      },
      "close": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide search bar"])}
      }
    },
    "zoomIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoom in"])},
    "zoomOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoom out"])},
    "activateZoomSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate zoom window"])},
    "deactivateZoomSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate zoom window"])},
    "activateHelpLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show help lines"])},
    "deactivateHelpLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide help lines"])},
    "fullZoomOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoom level 100%"])},
    "scaleContentArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fit image to content area of the figure"])},
    "scaleFullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fit image to figure"])},
    "toggleFigureNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show/Hide figure number"])},
    "scaleImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale image: "])},
    "scalingTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set scaling for the image of the figure"])},
    "figureNumberInputTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figure number"])}
  },
  "menububble": {
    "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bold"])},
    "italic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italic"])},
    "underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underline"])},
    "addReferenceSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add reference number"])},
    "addLibraryExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add library entry"])},
    "createCurve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New bezier-line"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "applyAiGenerated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply AI text"])}
  },
  "text-icon": {
    "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])},
    "italic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I"])},
    "underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U"])}
  },
  "clientLocalizedKey": {
    "INTERNAL_SERVER_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected server error has occurred. Please contact support."])},
    "INVALID_ENTITY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Invalid object ", _interpolate(_list(0))])},
    "ENTITY_ALREADY_EXISTS": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["There is already another application with the file number \"", _interpolate(_list(2)), "\", including a possibly deleted application."])},
    "REFERENCE_SIGN_NOT_UNIQUE_IN_APPLICATION_DOCUMENT": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Identifier '", _interpolate(_list(0)), "' and name '", _interpolate(_list(1)), "' of a reference number must be unique."])},
    "REFERENCE_SIGN_NAME_IS_TOO_LONG": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The identifier is limited to ", _interpolate(_list(0)), " characters."])},
    "REFERENCE_SIGN_LABEL_IS_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The identifier of a reference number must not consist of numbers only."])},
    "REFERENCE_SIGN_NAME_IS_EMPTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name of a reference number must not be empty."])},
    "REFERENCE_SIGN_DUPLICATE_WORD_STEMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A reference number is not allowed to contain two identical word stems."])},
    "MODEL_NOT_FOUND": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The requested ", _interpolate(_list(0)), " object with ID ", _interpolate(_list(1)), " could not be found."])},
    "DOCUMENT_EXPORT_ERROR": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["An error occurred while exporting document '", _interpolate(_list(0)), "'."])},
    "SEARCH_SPACES_IS_EMPTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A search space needs to be specified."])},
    "FIGURE_BASE_IMAGE_INVALID_FILE_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid image format: JPG, PNG and SVG files are supported only. "])},
    "FIGURE_BASE_IMAGE_CORRUPTED_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation failed. The image file is corrupted."])},
    "BLOCK_NOT_GENERATEABLE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected area cannot be generated."])},
    "FIGURE_RENDERING_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while rendering the figure."])},
    "PAYLOAD_TOO_LARGE_FIGURE_RENDERING_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The uploaded figure exceeds the maximum file size of 3MB."])},
    "FIGURE_RENDERING_DELETION_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to delete the element from the figure"])},
    "NETWORK_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server unavailable. Changes cannot be saved!"])},
    "UNDEFINED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A server error has occurred. Changes cannot be saved!"])},
    "INVALID_RICH_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The text contains invalid characters. The affected text block cannot be saved!"])},
    "UNDO_STACK_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while undoing. The change could not be undone."])},
    "REDO_STACK_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while restoring. The change could not be restored."])},
    "SPELLCHECK_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred during spell check."])},
    "SPELLCHECK_FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The spell check failed."])},
    "SPELLCHECK_WORD_ALREADY_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The spell check correction term is already in place."])},
    "SPELLCHECK_WORD_DOES_NOT_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spell check term not found."])},
    "SPELLCHECK_WORD_IS_EMPTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The spell check correction term must not be empty."])},
    "SPELLCHECK_WORD_IS_TOO_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The spell check correction term is too long."])},
    "SPELLCHECK_ERROR_TYPE_IS_TOO_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The spell check correction error type is too long."])},
    "LOCALIZED_BRICK_NOT_UNIQUE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["There is already a library entry '", _interpolate(_list(2)), "' with this type and language."])},
    "TERM_DEFINITION_NOT_UNIQUE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["There is already a library entry '", _interpolate(_list(1)), "' with this type and language."])},
    "DEFECT_EXPORT_IMAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certain figures for export are outdated or incorrect."])},
    "PASTE_SYMBOLS_FROM_OTHER_APPLICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbols from another application cannot be inserted"])},
    "SCALING_NOT_IN_RANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scaling has to be between 1 and 100 percent"])},
    "UPDATE_TEXT_IN_NON_TEXT_BLOCK": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["There was an attempt to change text of a non-text block with Id '", _interpolate(_list(1)), "'."])},
    "APPLICATION_DOCUMENT_DELETION_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application cannot be deleted as it is marked as non-deletable."])},
    "UPDATE_TEXT_IN_NON_TEXT_BLOCK": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["There was an attempt to change the text of an non-text block with id '", _interpolate(_list(1)), "'."])},
    "AI_TEMPLATE_SYNTAX_ERROR": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The Template contains a syntax error at line ", _interpolate(_list(0)), ", column ", _interpolate(_list(1)), "."])},
    "TEST_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Error Toast"])},
    "AI_TEMPLATE_NAME_IS_TOO_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ai-Template name is too long."])},
    "UPSTREAM_SERVICE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while calling an upstream service."])},
    "UPSTREAM_NOT_AUTH_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The upstream service request was denied due to insufficient permissions."])},
    "UPSTREAM_RATE_LIMIT_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The rate limit was exceeded while calling an upstream service."])}
  },
  "entity": {
    "ApplicationDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
    "Application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
    "GenericTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic Term"])},
    "Client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant"])},
    "AddressTmp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "PatentHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant"])},
    "Block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block"])},
    "TextBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text block"])},
    "GenericTermBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic Term block"])},
    "ClaimBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim block"])},
    "ClaimListBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim List block"])},
    "PrimaryClaimBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Claim block"])},
    "StructuralBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structural block"])},
    "RichText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
    "ReferenceSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference number"])},
    "ApplicationFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application figure"])},
    "FigureBaseImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figure base image"])},
    "Symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbol"])}
  },
  "nodes": {
    "reviewNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to manual changes in the block, it was not automatically regenerated.\nIf necessary, regenerate the block or change it manually.\nThe hint can be removed by clicking on the displayed icon."])}
  },
  "editorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])},
  "figuresEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figure editor"])},
  "palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palette"])},
  "library-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
  "filterPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by name or keyword"])},
  "loadLibraryManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load library entries"])},
  "emptyLibraryManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No library entries found"])},
  "deleteLibraryEntry": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Delete ", _interpolate(_list(0))])},
    "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you really want to delete ", _interpolate(_list(0)), " '", _interpolate(_list(1)), "'?"])}
  },
  "header": {
    "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User profile"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more functions"])},
    "tab": {
      "compose": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
        "search": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search & Replace"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search & Replace"])}
        },
        "spellingAndGrammar": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelling & Grammar"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelling & Grammar"])},
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check spelling"])},
            "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check spelling is disabled"])}
          },
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check spelling"])},
            "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check spelling is enabled"])}
          },
          "dictionary": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit dictionary"])},
            "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit dictionary"])}
          },
          "resetIgnoredWords": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset suggested corrections"])},
            "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset suggested corrections"])},
            "confirm": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset suggested corrections"])},
              "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to reset all ignored spelling correction suggestions for the current application?"])},
              "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
            }
          }
        },
        "coverSheet": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cover Sheet"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cover Sheet"])}
        },
        "generateAll": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate all"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate all"])}
        },
        "autoReplace": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-replace"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-replace"])}
        },
        "symbols": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbols"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbols"])}
        }
      },
      "check": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
        "dictionary": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dictionary"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dictionary"])}
        },
        "warnings": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnings"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnings"])}
        }
      },
      "statusAndAccess": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status & access"])},
        "approveForSubmission": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve for submission"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve for submission"])}
        },
        "newVersion": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New version"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New version"])}
        },
        "versions": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versions"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versions"])}
        },
        "access": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])}
        }
      },
      "view": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
        "splitTextHorizontally": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split text horizontally"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split text horizontally"])}
        },
        "splitTextVertically": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split text vertically"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split text vertically"])}
        },
        "removeSplit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove split"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove split"])}
        },
        "showFigureEditor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Figure Editor"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Figure Editor"])}
        },
        "hideFigureEditor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Figure Editor"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Figure Editor"])}
        },
        "resetTextZoom": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset text zoom"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset text zoom"])}
        }
      },
      "help": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])}
      }
    },
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin menu"])},
    "unpin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse menu"])}
  },
  "command": {
    "description": {
      "action": {
        "COMMAND_BLOCK_CREATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " created"])},
        "COMMAND_BLOCK_UPDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " changed"])},
        "COMMAND_BLOCK_DELETE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " deleted"])},
        "COMMAND_BLOCK_GENERATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " generated"])},
        "COMMAND_BLOCK_GENERATE_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated all"])},
        "COMMAND_BLOCK_INLINE_UPDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " changed to inline"])},
        "COMMAND_BLOCK_PARAGRAPH_UPDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " changed to paragraph"])},
        "COMMAND_BLOCK_APPLY_REFERENCE_SIGNS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference numbers applied to application text"])}
      },
      "phrase": {
        "Within": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " in ", _interpolate(_list(1))])},
        "Refering": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " of ", _interpolate(_list(1))])}
      },
      "delimiter": {
        "Listing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", "])},
        "Ellipsis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "..."])},
        "Join": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " and ", _interpolate(_list(1))])}
      }
    }
  },
  "licenses": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License information"])},
    "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This product utilizes the libraries as follows:"])},
    "load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The license could not be loaded."])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading licenses"])}
  },
  "about": {
    "info": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The <b>Terms of Use</b> for the Patent Engine<sup>&reg;</sup> and the associated <b>data protection measures</b> are regulated in a contract between DESIGN TO IP GmbH and your company. For more information, please contact the responsible person in your company. "])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
    },
    "help": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you need assistance and cannot contact us on the phone number provided, please send us a message to the email address below. We will get back to you as soon as possible."])}
    },
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])}
  },
  "symbolsList": {
    "curve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezier-line"])},
    "dashedCurve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashed bezier-line"])},
    "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line"])},
    "dashedLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashed line"])},
    "arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrow"])},
    "dashedArrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashed arrow"])},
    "brace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bracket"])},
    "bottomOpenBrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bracket (right side open)"])},
    "topOpenBrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bracket (left side open)"])},
    "horizontalHelpLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal helper line"])},
    "verticalHelpLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical helper line"])}
  },
  "spellcheck": {
    "category": {
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unknown error"])}
    },
    "replacement": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace by:"])}
    },
    "info": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More details..."])}
    },
    "addToDictionary": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to dictionary."])}
    },
    "ignore": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore in this application."])}
    }
  },
  "spellcheckDictionaryManagement": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit dictionary"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please define here all words that should not be checked by the spell checker:"])},
    "createEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create entry"])},
    "word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry"])},
    "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load entries."])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No entires found."])},
    "delete": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete entry"])},
      "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Remove \"", _interpolate(_list(0)), "\" from dictionary?"])}
    }
  },
  "copyReferenceSignMarkerDialog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert reference numbers"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one of the pasted reference numbers is not part of the application. Should they be added?"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
  },
  "cookies": {
    "cookieBannerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie settings"])},
    "cookieBannerMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use cookies on our website. These cookies enable improved, more personalized features for our website visitors. You can set your privacy preferences below. Detailed information and how you can revoke your consent at any time can be found in our "])},
    "cookieBannerFill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" and in "])},
    "cookieBannerEssential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essential cookies"])},
    "cookieBannerFunctional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functional cookies"])},
    "cookieBannerAcceptAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept all"])},
    "cookieBannerAcceptChoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept selection"])},
    "cookieSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change cookie settings"])}
  },
  "regulatoryInformation": {
    "languagePath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/en"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data privacy"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
    "sla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])}
  },
  "aiAssistantDialog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI-Assistant"])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input:"])},
    "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert your prompt here"])},
    "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select model"])},
    "substitutePlaceholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Substitute Placeholders"])},
    "sendButon": {
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer is computed..."])}
    },
    "output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer:"])},
    "outputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The answer of the AI-model is displayed here"])},
    "rateAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate answer:"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AI"])},
    "hoverText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open AI-assistent"])},
    "hoverAiTemplateButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select request template"])},
    "hoverCloseButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close dialog"])},
    "unexpectedInterpretationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an unexpected error interpreting an AI-Template"])},
    "placeholderSubstitutionInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait..."])}
  },
  "magicButton": {
    "hoverText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate parts of the application by AI"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate all:"])}
  },
  "magicButtonDropdown": {
    "phrase": {
      "Within": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " of ", _interpolate(_list(1))])}
    }
  }
}