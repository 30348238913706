<template>
  <div class="menububble-container">
    <PengineEditorMenuBubble v-if="editor !== null"
                             :editor="editor"
                             v-slot="{commands, setBold, setItalic, setUnderline, setAiGenerated, isActive, menu}">
      <div ref="content" class="menububble" :class="{ 'is-active': (menu.isActive && isBlockFormatable()) }" :style="position()">
        <button class="icon-button contains-text-icon" :class="{'is-active': isActive('bold')}" :title="$t('menububble.bold')"
                @mouseup.left="() => {  removeMouseDownListener(); afterExecution();}"
                @mousedown.left="() => {ignoreMouseDownEvent(); setBold();}">
          <span class="text-icon" style="font-weight: bold;">{{ $t('text-icon.bold') }}</span>
        </button>
        <button class="icon-button contains-text-icon" :class="{'is-active': isActive('italic')}" :title="$t('menububble.italic')"
                @mouseup.left="() => {removeMouseDownListener(); afterExecution();}"
                @mousedown.left="() => {ignoreMouseDownEvent(); setItalic();}">
          <span class="text-icon" style="font-style: italic;">{{ $t('text-icon.italic') }}</span>
        </button>
        <button class="icon-button contains-text-icon" :class="{'is-active': isActive('underline')}" :title="$t('menububble.underline')"
                @mouseup.left="() => {removeMouseDownListener(); afterExecution();}"
                @mousedown.left="() => {ignoreMouseDownEvent(); setUnderline();}">
          <span class="text-icon" style="text-decoration: underline;">{{ $t('text-icon.underline') }}</span>
        </button>
        <button class="icon-button" :title="$t('menububble.addReferenceSign')"
                @mouseup.left="() => {removeMouseDownListener(); afterExecution();}"
                @mousedown.left="() => {ignoreMouseDownEvent(); commands.addReferenceSignExtension();}">
          <i class="exi exi-add-reference-sign"/>
        </button>
        <button class="icon-button" :title="$t('menububble.addLibraryExtension')"
                @mouseup.left="() => {removeMouseDownListener(); afterExecution();}"
                @mousedown.left="() => {ignoreMouseDownEventButAddToLibrary();}">
          <i class="exi exi-add-library"/>
        </button>
        <button class="icon-button" :title="$t('menububble.applyAiGenerated')"
                @mouseup.left="() => {removeMouseDownListener(); afterExecution();}"
                @mousedown.left="() => {ignoreMouseDownEvent(); setAiGenerated();}">
          <i class="exi-md exi-ai-generated"/>
        </button>
      </div>
    </PengineEditorMenuBubble>
    <SearchResultDialog ref="searchResultDialog" :showButton="false"/>
  </div>

</template>

<script lang="ts">
import {Component, Prop, Ref, toNative, Vue} from 'vue-facing-decorator';
import {Editor} from '@tiptap/vue-3';
import {Node as PmNode} from "@tiptap/pm/model";
import SearchResultDialog, {SearchResultDialog as SearchResultDialogClass} from '@/components/SearchResultDialog.vue';
import ApplicationModule from '@/store/modules/ApplicationModule';
import PengineEditorMenuBubble from '@/components/applicationEditor/plugins/MenuBubble/PengineEditorMenuBubble';
import {SemanticType} from '@/api/models/editor.model';
import {calcBubblePositioningStyleAbove} from '@/components/applicationEditor/plugins/MenuBubble/PengineEditorMenuBubble.util';

@Component(
  {
    components: {
      PengineEditorMenuBubble,
      SearchResultDialog
    }
  })
class MenuBubble extends Vue {

  @Prop({required: true}) editor!: Editor | null;

  @Ref('searchResultDialog')
  private searchResultDialog!: SearchResultDialogClass;

  @Ref('content') private content!: HTMLElement;

  @Prop()
  private afterCommandExecution!: () => void;
  private mouseDownListener: ((event: MouseEvent) => void) | undefined;

  private htmlContainer: HTMLElement | null = null;

  /**
   * Ignores the mousedown event when the user clicks on the Bubble Menu buttons.
   * by adding a listener on the mousedown event which prevents its default behavior and as a consequence
   * prevent the triggering of blur event (which causes the disappearing of placeholder node).
   *
   */
  ignoreMouseDownEvent() {
    if (!this.mouseDownListener) {
      this.mouseDownListener = (event: MouseEvent): void => {
        event.preventDefault();
      };
      document.addEventListener('mousedown', this.mouseDownListener);
    }

    // make sure to remove the mouse down listener if "mouseup" takes too long or is lost
    window.setTimeout(() => this.removeMouseDownListener(), 100);
  }

  /**
   * Holds the same logic of the method "ignoreMouseDownEvent" but it is called only when the user wants to add a term to the library.
   *  N.B to execute the add to Library command we need to save the selection and docSelection before loosing the focus.
   */
  private ignoreMouseDownEventButAddToLibrary() {
    if (!this.mouseDownListener) {
      this.mouseDownListener = (event: MouseEvent): void => {

        if (this.editor === null) {
          return;
        }
        let docSelection = document.getSelection() || "";
        docSelection = docSelection.toString();
        const selection = this.editor.state.selection;
        this.addToLibrary(selection, docSelection);

        event.preventDefault();
      };
      document.addEventListener('mousedown', this.mouseDownListener);
    }

    // make sure to remove the mouse down listener if "mouseup" takes too long or is lost
    window.setTimeout(() => this.removeMouseDownListener(), 100);
  }

  private removeMouseDownListener() {
    if (this.mouseDownListener !== undefined) {
      document.removeEventListener('mousedown', this.mouseDownListener);
      this.mouseDownListener = undefined;
    }
  }

  private semanticTypesOfBlocksWithNoFormatting = [
    SemanticType.SHORT_DESCRIPTION_FIGURE_TEXT, SemanticType.REFERENCE_SIGN_LIST_ROW_NAME,
    SemanticType.APPLICATION_ABSTRACT_FIGURE_TEXT];

  position(): string {
    if (!this.editor || !this.content) {
      return '';
    }
    const selection = this.editor.state.selection;
    if (selection.empty) {
      return '';
    }
    return calcBubblePositioningStyleAbove(this.editor.view, this.content, selection.from, selection.to);
  }

  private addToLibrary(selection: { from: number; to: number }, docSelection: string): void {
    if (!this.editor) {
      return;
    }
    const applicationDocument = ApplicationModule.currentApplicationDocument;
    let locale
    if (applicationDocument) {
      locale = applicationDocument.locale;
    }

    this.editor.commands.focus(selection.from);
    this.searchResultDialog.openForLibEntryCreation([], docSelection, undefined, locale);
  }

  private afterExecution() {
    if (this.afterCommandExecution) {
      this.afterCommandExecution();
    }
  }


  private isBlockFormatable(): boolean {
    if (!this.editor) {
      return false;
    }
    const semanticTypeAnchor = this.editor.state.selection.$anchor.node().attrs.semanticType;
    const semanticTypeHead = this.editor.state.selection.$head.node().attrs.semanticType;

    const headOrTailIsNotFormatable =
      (this.semanticTypesOfBlocksWithNoFormatting.includes(semanticTypeAnchor) ||
        this.semanticTypesOfBlocksWithNoFormatting.includes(semanticTypeHead));

    //early exit, if head or anchor already at non-formatable block
    if (headOrTailIsNotFormatable) {
      return false;
    }

    //look at every blocks semanticType
    const selection = this.editor.state.selection;
    const currentChild = selection.content().content.firstChild;
    if (currentChild !== undefined && currentChild !== null) {
      return this.traverseChildren(currentChild);
    }

    return true;
  }

  private traverseChildren(node: PmNode): boolean {
    let allBlocksFormatable = true;
    node.forEach((childNode: PmNode) => {
      if (this.semanticTypesOfBlocksWithNoFormatting.includes(childNode.attrs.semanticType)) {
        allBlocksFormatable = false;
      }
      if (!node.isLeaf) {
        childNode.forEach((child: PmNode) => allBlocksFormatable = allBlocksFormatable && this.traverseChildren(child));
      }
    });
    return allBlocksFormatable;
  }
}

export default toNative(MenuBubble);
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

.menububble-container {
  overflow: visible;

  .menububble {
    position: absolute;
    display: -webkit-box;
    display: flex;
    z-index: 999;
    background-color: $popover-background-color;
    box-shadow: 3px 3px 6px $popover-shadow-color;
    padding: .3rem;
    margin-bottom: .5rem;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .menububble.is-active {
    opacity: 1;
    visibility: visible
  }
}
</style>
