<template>
  <PatentEngineMenuBar :editor="editorInstance">
    <div ref="editorMenuBar" class="menubar">
      <div class="menubar-row">
        <div class="space-between-container" :style="visible">
          <div class="create-delete-button-container">
            <ApplyAiGeneratedText :key="computedActiveEditorSelection+1" :editor="editorInstance"/>
            <CreateBlock :key="computedActiveEditorSelection+2" :editor="editorInstance" v-show="selectedBlockGuid"/>
            <DeleteBlock :key="computedActiveEditorSelection+3" :editor="editorInstance" v-show="selectedBlockGuid"/>
            <InlineBlock :key="computedActiveEditorSelection+4" :editor="editorInstance" v-show="selectedBlockGuid"/>
          </div>
        </div>
        <div class="generate-undo-redo">
          <div class="generate-button-container">
            <GenerateBlock :editor="editorInstance" v-show="selectedBlockGuid"/>
          </div>
          <GenerateAll :editor="editor"/>

          <span ref="magicButtonRef" class="magic-button" :style="isAutoFillRunning" >
              <b-button
                class="magic-button-inner"
                :title="$t('magicButton.hoverText')"
                @click="magicButtonClicked()"
                :loading="showSpinner"
                :disabled="isMagicButtonDisabled">
                <i v-if="showSpinner"/>
                <i v-else class="exi ai-feature-button magic-button-icon"/>
              </b-button>
          </span>

          <div ref="magicButtonDropdownRef" class="magic-button-dropdown-div" :style="magicButtonDropdownPosition">
            <ul v-if="showMagicButtonDropdown" class="dropdown-menu">
              <li class="magic-button-dropdown-title" v-show="showMagicButtonDropdown">{{ $t('magicButton.title') }}</li>
              <li v-for="(value, index) in magicButtonAiTemplates" v-bind:key="index" class="dropdown-submenu">
                <a tabindex="-1" @mouseover="selectedSubMenuIndex = index" class="dropdown-submenu-entry" > {{ value.key.displayedText }}
                  <span class="caret"></span></a>
                <ul v-if="selectedSubMenuIndex === index" class="dropdown-menu">
                  <li v-for="(subValue, subIndex) in value.children" v-bind:key="subIndex" @click="sendLlmAutoFillRequest(subValue)">
                    <a tabindex="-1">{{ subValue.aiTemplateName }}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <History :editor="editor" :applicationeditor="applicationeditor"/>
        </div>
      </div>

      <div v-show="toggleSearchBar"
           class="menubar-row">
        <SearchBar ref="searchBar" :editor="editor" :closeCallback="closeSearchbarPressed"/>
      </div>
    </div>
  </PatentEngineMenuBar>
</template>

<script lang="ts">
import {Component, Prop, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import CreateBlock from '@/components/applicationEditor/menubar/CreateBlock.vue';
import DeleteBlock from '@/components/applicationEditor/menubar/DeleteBlock.vue';
import InlineBlock from '@/components/applicationEditor/menubar/InlineBlock.vue';
import ApplyAiGeneratedText from '@/components/applicationEditor/menubar/ApplyAiGeneratedText.vue';
import History from '@/components/applicationEditor/menubar/History.vue';
import {ApplicationEditor as ApplicationEditorClass, NamedEditor} from '@/components/ApplicationEditor.vue';
import GenerateBlock from '@/components/applicationEditor/menubar/Generate.vue';
import GenerateAll from '@/components/applicationEditor/menubar/GenerateAll.vue';
import Paste from '@/components/applicationEditor/menubar/Paste.vue';
import SearchBar, {SearchBar as SearchBarClass} from '@/components/applicationEditor/menubar/SearchBar.vue';
import EditorModule from '@/store/modules/EditorModule';
import PatentEngineMenuBar from '@/components/header/PatentEngineMenuBar.vue';
import AiTemplateModule from "@/store/modules/AiTemplateModule";
import CompletionResultModule from '@/store/modules/CompletionResultModule';
import {LlmAutoFillRequest} from '@/api/models/llm.model';
import AiAssistantModule from '@/store/modules/AiAssistantModule';
import {AiTemplate} from '@/api/models/aiTemplate.model';
import {GetLocalizedExceptionMessageFor} from '@/api/services/exceptions';
import {LocalizedMessage} from '@/api/models/exception.model';
import {showToast} from '@/api';
import {useDefaultErrorHandling} from '@/errorHandling';
import AuthModule from '@/store/modules/AuthModule';
import ApplicationModule from '@/store/modules/ApplicationModule';

@Component(
  {
    components: {
      PatentEngineMenuBar,
      History,
      DeleteBlock,
      CreateBlock,
      InlineBlock,
      GenerateBlock,
      GenerateAll,
      ApplyAiGeneratedText,
      Paste,
      SearchBar
    }
  })
class Menubar extends Vue {

  @Prop({required: true}) editor!: NamedEditor;
  @Prop({required: true}) applicationeditor!: ApplicationEditorClass;
  @Ref('editorMenuBar') private editorMenuBar!: HTMLElement;
  @Ref('searchBar') private searchBar!: SearchBarClass;
  @Ref('magicButtonRef') private magicButton!: HTMLDivElement;
  @Ref('magicButtonDropdownRef') private magicButtonDropdownRef!: HTMLDivElement;

  private showMagicButtonDropdown = false;
  private magicButtonDropdownTopAndLeft = {top: 0, right: 0};
  private selectedSubMenuIndex = -1;

  mounted() {
    document.addEventListener('click', this.clickedOutsideMagicButton)
  }

  beforeUnmount() {
    document.removeEventListener('click', this.clickedOutsideMagicButton)
  }

  get isAutoFillRunning(): any {
    if (this.showSpinner) {
      return {cursor: 'not-allowed'}
    } else {
      return {}
    }
  }

  get isMagicButtonDisabled(): boolean {
    return !AuthModule.isAiAssistantEnabled || this.magicButtonAiTemplates.length === 0;
  }

  get showSpinner(): boolean {
    return EditorModule.isLoading;
  }

  get magicButtonDropdownPosition(): any {
    return {top: this.magicButtonDropdownTopAndLeft.top + 50 + 'px', right: this.magicButtonDropdownTopAndLeft.right + 'px'};
  }

  private magicButtonClicked() {
    this.magicButtonDropdownTopAndLeft.right = window.innerWidth - this.magicButton.getBoundingClientRect().right;
    this.magicButtonDropdownTopAndLeft.top = this.magicButton.getBoundingClientRect().top;
    this.showMagicButtonDropdown = !this.showMagicButtonDropdown;
  }

  /**
   * Close dropdown of magic-button if clicked outside.
   */
  clickedOutsideMagicButton(event: MouseEvent) {
    const target = event.target as HTMLElement;

    if (!this.magicButton.contains(target)
      && !this.magicButtonDropdownRef.contains(target)) {
      this.showMagicButtonDropdown = false;
      this.selectedSubMenuIndex = -1;
    }
  }

  get magicButtonAiTemplates(): any[] {
    return AiTemplateModule.magicButtonAiTemplates;
  }

  get toggleSearchBar(): boolean {
    return EditorModule.isSearchBarVisible;
  }

  get computedActiveEditorSelection() {
    if (!this.editor) {
      return "";
    }
    return `${this.editor?.state.selection.from} ${this.editor?.state.selection.to}`;
  }

  get selectedBlockGuid(): string | null {
    return EditorModule.selectionGuidForEditor;
  }

  private closeSearchbarPressed(): void {
    EditorModule.setSearchBarVisible(false);
  }

  get searchTerm(): string {
    return EditorModule.searchTermOnKeyDown;
  }

  private sendLlmAutoFillRequest(aiTemplate: AiTemplate) {
    const showToastAndUseDefaultErrorHandling = (error: any) => {
      const message = GetLocalizedExceptionMessageFor(error as LocalizedMessage);
      showToast(message);
      useDefaultErrorHandling(error)
      ApplicationModule.reloadApplicationDocument();
    }
    if(aiTemplate.aiTemplateGuid === undefined
        || AiAssistantModule.currentApplicationDocumentUuid === null) {
     return;
    }
    const llmAutoFillRequest: LlmAutoFillRequest = {
      aiTemplateGuid: aiTemplate.aiTemplateGuid, applicationDocumentGuid: AiAssistantModule.currentApplicationDocumentUuid
    };
      this.showMagicButtonDropdown = false;
      CompletionResultModule.sendLlmAutoFillRequest(llmAutoFillRequest)
        .catch(showToastAndUseDefaultErrorHandling);
      // dann anmeldung neu laden
  }

  @Watch('searchTerm', {immediate: true})
  private searchTermChanged(newTerm: string) {
    if (this.searchBar && this.toggleSearchBar && newTerm) {
      setTimeout(this.searchBar.setFocus, 100);
    }
  }

  @Watch('toggleSearchBar', {immediate: true})
  private toggleSearchBarChanged(toggleSearchBar: boolean) {
    if (toggleSearchBar) {
      // On open
      if (this.searchBar) {
        setTimeout(this.searchBar.setFocus, 100);
      }
    } else {
      // On close
      if (this.searchBar) {
        this.searchBar.clearSearch();
      }
    }
  }

  @Watch('displaySearchBarOnKeyDown', {immediate: true})
  private displaySearchBarOnKeyDownChanged(toggleSearchBar: boolean) {
    if (toggleSearchBar) {
      if (this.searchBar) {
        setTimeout(this.searchBar.setFocus, 100);
      }
      EditorModule.setSearchBarVisible(false);

    }
  }

  get editorInstance(): NamedEditor {
    return this.editor;
  }

  get editorKey(): string {
    if (!this.editorInstance) {
      return ""
    }
    return this.editorInstance.editorName;
  }

  get visible() {
    return {visibility: 'visible'};
  }
}

export default toNative(Menubar);
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<!-- not 'scoped' so styling applies to generated buttons -->
<style lang="scss">
@import "src/assets/styles/colors";
@import "src/assets/styles/constants";

.magic-button {
  padding-left: 8px;
  padding-right: 8px;
}

.magic-button-inner {
  background-color: #FFFFFF;
  border-color: #FFFFFF !important;

  &:hover {
    background-color: #FFFFFF;
  }
}

.magic-button-icon {
  background-color: $pengine-orange-dark-dark !important;
  margin-right: 0 !important;
  padding-right: 0 !important;
  height: 18px;
  width: 18px;
  .i {
    &.is-active {
      background-color: $pengine-blue-dark;;
    }

    &:hover {
      background-color: $pengine-blue-dark;;
    }
  }
}

// Wrapper
.menubar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 8px;

  .menubar-row {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  // This will reserve the space the search bar will take
  .search-bar-placeholder {
    width: 100%;
    height: calc(#{$search-bar-height} - 6px);
  }

  .space-between-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: fit-content;
  }

  .generate-undo-redo {
    display: flex;
    justify-content: flex-end;
    width: fit-content;
    flex-flow: row wrap;
    height: fit-content;
  }

  .create-delete-button-container {
    display: flex;
    flex-flow: row wrap;
    text-align: left;
    min-width: 1px;
    min-height: 35px;

  }

  .generate-button-container {
    display: flex;
    flex-flow: row-reverse wrap;
    text-align: right;
  }

  // Button flex layout
  button {
    span {
      display: flex;
      vertical-align: middle;
      line-height: 18px;

      i {
        margin-right: 8px;
      }
    }

    cursor: pointer;
    padding: 8px 8px;
    font-size: $font-size-normal;
  }

  // Standard
  button:not(.icon-button) {
    background-color: transparent;
    color: $text-color;
  }

  button.delete-button:not(.icon-button):hover:enabled {
    background-color: transparent;
    color: $pengine-delete-red;
  }

  // Standard hover
  button:hover:not(.icon-button,.delete-button):enabled {
    background-color: transparent;
    color: $pengine-blue-dark;
  }

  button:disabled {
    background-color: transparent;
    border: 1px solid $pengine-grey-light;
    cursor: not-allowed;
    color: $pengine-grey;
  }

  .search-bar-panel {
    width: 100%;
  }

  .search-bar-dropdown {
    z-index: 8; // Must be behind modal dialogs
    position: absolute;
    vertical-align: top;
    width: 100%;
    // top: 46px;
    left: 0px;

    .dropdown-menu {
      width: calc(100% - 1px);

      .dropdown-content {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-bottom: $pengine-grey solid 1px;
      }
    }
  }
}
</style>

<!--
Style section for the magicButton and its dropdown
-->
<style lang="scss" scoped>

.magic-button-dropdown-div {
  border-top-color: #4F4F4F !important;

  position: absolute;
  z-index: 1200;
  background-color: white;
  -webkit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);

  .magic-button-dropdown-title {
    text-align: left;
    padding-left: 5px;
    font-size: 14px;
    font-weight: normal;
  }
}

// Custom dropdown
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-top: 4px dashed transparent;
  border-right: 4px solid transparent;
  border-left: 4px solid ;
  border-bottom: 4px solid transparent;
  //border-width:4px 0 4px 4px;
}

.dropdown-menu {
  display: inline-block;
  position: relative;
  top: 100%;
  left: 0;

  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
}

.dropdown-submenu {
  position: relative;

  .dropdown-menu {
    position: absolute;
    top: 0;
    // this is necessary to translate/place the element to the left (by 100%) of its parent and move it, in addition, 10px to left
    // to build the small gab between the first and second dropdown level
    transform: translate(-100%) translate(-5px);
    margin-top: -5px;
  }
}

// TODO: from here to end of this style section: Which parts are needed, which are not used?
.dropdown-menu > li > a {
  display: block;
  padding: 3px 5px 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
</style>
