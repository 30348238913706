<template>
  <span>
      <b-dropdown position="is-bottom-right"
                  :mobile-modal="false"
                  append-to-body
                  aria-role="list"
                  trap-focus :disabled="isLoading">
        <template #trigger>
          <b-button class="icon-button dropdown-button apply-ai-generated-text"
                    :title="$t(`menubar.aiTextHover`)">
            <i class="exi exi-ai-generated"/>
            {{ $t(`menubar.aiTextTitle`) }}
          </b-button>
        </template>
        <b-dropdown-item key="applyForBlock"
                         aria-role="listitem"
                         v-show="selectedBlockGuid"
                         @click="applyForBlock()">
          {{ $t(`menubar.applyForBlock`) }}
        </b-dropdown-item>
        <b-dropdown-item key="applyForDocument"
                         aria-role="listitem"
                         @click="applyForDocument()">
          {{ $t(`menubar.applyForDocument`) }}
        </b-dropdown-item>
      </b-dropdown>
  </span>
</template>

<script lang="ts">
import {Component, Inject, Prop, toNative, Vue} from 'vue-facing-decorator';
import EditorModule from '@/store/modules/EditorModule';
import {NamedEditor, SaveChangesFn} from '@/components/ApplicationEditor.vue';
import {EditorState} from '@tiptap/pm/state';
import {useDefaultErrorHandling} from '@/errorHandling';
import {findNodeRangeOfGuid} from '@/components/applicationEditor/utils/node.util';
import AiAssistantModule from '@/store/modules/AiAssistantModule';
import {removeMarks} from '@/components/applicationEditor/utils/mark.util';

/**
 * Component to add buttons for all semantic types that may be added to the current active node or it's parents
 */
@Component
class ApplyAiGeneratedText extends Vue {
  @Inject({from: 'applicationEditor.saveChanges'}) saveEditorChangesFn!: SaveChangesFn;
  @Inject({from: 'applicationEditor.activeEditorState'}) state!: EditorState;
  @Prop({required: true}) editor!: NamedEditor;

  get isLoading(): boolean {
    return EditorModule.isLoading;
  }

  get selectedBlockGuid(): string | null {
    return AiAssistantModule.lastSelectedBlock;
  }

  applyForBlock(): boolean {
    if (!this.selectedBlockGuid) {
      return false;
    }
    const nodeRange = findNodeRangeOfGuid(this.state.doc, this.selectedBlockGuid);

    if (!nodeRange) {
      return false;
    }
    const {view} = this.editor;
    const {tr} = this.state;
    const markType = this.state.schema.marks.aiGenerated;

    removeMarks(tr, nodeRange.start, nodeRange.end, [markType]);
    view.dispatch(tr);

    this.saveEditorChangesFn(false)
      .catch(useDefaultErrorHandling);

    return true;
  }

  applyForDocument() {
    const {view} = this.editor;
    const {tr} = this.state;

    const docStart = 0;
    const docEnd = this.state.doc.content.size;
    const markType = this.state.schema.marks.aiGenerated;

    removeMarks(tr, docStart, docEnd, [markType]);
    view.dispatch(tr);

    this.saveEditorChangesFn(false)
      .catch(useDefaultErrorHandling);

    return true;
  }
}

export default toNative(ApplyAiGeneratedText);
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/colors.scss';

// Remove button border in any case
button.dropdown-button {
  border: 0 solid transparent !important;

  &:hover:enabled, &:focus:enabled {
    border: 0 solid transparent !important;
  }
}
.apply-ai-generated-text {
  color: $pengine-grey-dark-dark;
  &:hover {
    color: $pengine-blue-dark !important;
  }
}
</style>
