import {AbstractBlockViewModel, DocumentUpdate, LocalVmUpdateName, StructuralBlockViewModel, VmUpdateName} from '@/api/models/editor.model';
import {Editor} from "@tiptap/vue-3";

/**
 * Search for a specific block in the block structure provided by it's root.
 * @param block the block of the block structure to search in
 * @param predicate the predicate to check for a match
 */
function searchBlockByPredicate(block: AbstractBlockViewModel | null,
                                predicate: (block: AbstractBlockViewModel) => boolean): AbstractBlockViewModel | null {
  if (!block) {
    return null;
  }

  // Check the block
  if (predicate(block)) {
    return block;
  }

  // Descent recursively into children if they exist
  if (Object.getOwnPropertyNames(block).includes('children')) {
    const structuralBlockViewModel: StructuralBlockViewModel = block as StructuralBlockViewModel;
    const found = structuralBlockViewModel.children
      .map((child) => searchBlockByPredicate(child, predicate))
      .find((found) => (found));
    return (found) ? found : null; // map undefined to null
  }
  return null;
}

/**
 * Search for a block with a specific semantic type in the block structure provided by it's root.
 * @param block the block of the block structure to search in
 * @param semanticType the semantic type to search for
 */
export function searchForBlockBySemanticType(block: AbstractBlockViewModel, semanticType: string): AbstractBlockViewModel | null {
  return searchBlockByPredicate(block, (block) => (block.semanticType === semanticType));
}

/**
 * Search for a block by it's GUID in the block structure provided by it's root.
 * @param block the block of the block structure to search in
 * @param guid the GUID of the block to find
 */
export function searchBlockByGuid(block: AbstractBlockViewModel | null, guid: string): AbstractBlockViewModel | null {
  return searchBlockByPredicate(block, (block) => (block.guid === guid));
}

/***
 * Finds all blocks from the targetGuid to its ancestors.
 * The first element in the list is the block with the given targetGuid GUID.
 * The last element is the ancestor.
 *
 * @param ancestor this is usually the root block when called externaly. The block must be an ancestor of the targetGuid.
 * @param targetGuid the GUID of the leaf block that is potentially deeply nested inside the ancestor
 * @return a list of all blocks in the ancestor chain (the lineage) or an empty list if the targetGuid can not be found
 */
export function lineageForBlock(ancestor: StructuralBlockViewModel, targetGuid: string): AbstractBlockViewModel[] {
  if (ancestor.guid === targetGuid) {
    return [ancestor]
  }
  if (ancestor.children === undefined) {
    return [];
  }
  return ancestor.children.flatMap(value => {
    let subChain = lineageForBlock(value as StructuralBlockViewModel, targetGuid);
    if (subChain.length > 0) {
      subChain = [...subChain, ancestor];
    }
    return subChain;
  })
}

/**
 * Sets all values of the relevant fields of a target block to those of the source block.
 * @param source the source block to get the values from
 * @param target the target block to set the values in
 */
function updateBlockContents(source: AbstractBlockViewModel, target: AbstractBlockViewModel) {
  const sourceProperties = Object.getOwnPropertyNames(source);
  const targetProperties = Object.getOwnPropertyNames(target);

  // adopt values of source properties into target
  targetProperties
    // filter immutable properties
    .filter((prop) => !['__ob__', 'semanticType', 'guid', 'children'].includes(prop))
    // filter properties not included in source
    .filter((prop) => sourceProperties.includes(prop))
    // adopt properties from source
    .forEach((prop) => (target as never)[prop] = (source as never) [prop]);
}

/**
 * Searches for the original block in the provided root block and updates it's contents
 * @param blockUpdates the block with the updated content
 * @param rootBlock the root block to search in for the orignal block
 */
export function updateBlock(blockUpdates: AbstractBlockViewModel, rootBlock: AbstractBlockViewModel | null) {
  const originalBlock: AbstractBlockViewModel | null = searchBlockByGuid(rootBlock, blockUpdates.guid);
  if (originalBlock) {
    updateBlockContents(blockUpdates, originalBlock);
  }
}

export function updateBlockOnAutoFill(blockText: string, originalBlock: AbstractBlockViewModel | null) {
  if (originalBlock) {
    updateBlockContentsOnAutoFill(blockText, originalBlock);
  }
}

function updateBlockContentsOnAutoFill(blockText: string, target: AbstractBlockViewModel) {
  const targetProperties = ['richText']
  // TODO: Maybe add mark via transaction.addMark.
  const source = {richText: `<span class="ai-generated">${blockText}</span>`};
  // adopt values of source properties into target

  targetProperties
    // adopt properties from source
    .forEach((prop) => (target as never)[prop] = (source as never) [prop]);
}

/**
 * Searches fot the original block in the provided root block and updates it's contents and its children
 * @param blockUpdates the block with the updated content
 * @param rootBlock the root block to search in for the orignal block
 */
export function replaceBlock(blockUpdates: AbstractBlockViewModel, rootBlock: AbstractBlockViewModel | null) {
  const originalBlock: AbstractBlockViewModel | null = searchBlockByGuid(rootBlock, blockUpdates.guid);
  if (originalBlock) {
    const sourceProperties = Object.getOwnPropertyNames(blockUpdates);
    Object.getOwnPropertyNames(originalBlock)
      // filter immutable properties
      .filter((prop) => !['__ob__', 'guid'].includes(prop))
      // filter properties not included in source
      .filter((prop) => sourceProperties.includes(prop))
      // adopt properties from source
      .forEach((prop) => (originalBlock as never)[prop] = (blockUpdates as never) [prop]);
  }
}

/**
 * Checks the parents and further ancestors and returns the first logical block found.
 * If the given block itself is a logical block, the given block is returned.
 * @param block the nearest logical ancestor
 */
export function findNearestLogicalAncestor(block: AbstractBlockViewModel): AbstractBlockViewModel {
  if (!block.parent || block.logicalBlock) {
    return block;
  }
  return findNearestLogicalAncestor(block.parent);
}


/**
 * Helper method to retrieve the latest DocumentUpdate in the list of updates
 * @param update The array of document updates
 */
export function latestUpdate(update: DocumentUpdate[]): DocumentUpdate {
  if (update.length < 1) {
    // Ensure a new DocumentUpdate gets pushed (EditorModule::pushDocumentUpdate)
    // into the update list after clearing (EditorModule::clearUpdatedNodes) the list.
    throw new Error('Invalid State: \'update\' should not be empty!');
  }
  return update[update.length - 1];
}

/**
 * Helper method to push a new DocumentUpdate to the end of the given update array.
 * @param update The updates where the new update will be appended.
 * @param kind The name of the update for deugging purposes
 */
export function pushNew(update: DocumentUpdate[], kind: VmUpdateName | LocalVmUpdateName): DocumentUpdate[] {
  update.push(new DocumentUpdate(kind));
  return update;
}

/**
 * Finds the next textblock starting at a cords based position
 * @param editor tiptap editor
 * @param clientX starting position X (compatible to mouse event clientX)
 * @param clientY starting position Y (compatible to mouse event clientY)
 * @param offsetX the current distance from the start position in X direction
 * @param offsetY the current distance from the start position in Y direction
 * @param stepX if not found at the current position, the value used to adjust the X position for the next test
 * @param stepY if not found at the current position, the value used to adjust the Y position for the next test
 * @param max maximum distance to test regarding the original position
 * @return The document position of the first found textblock, -1 if no block is found
 */
function findNextTextblockPosition(editor: Editor, clientX: number, clientY: number, offsetX: number, offsetY: number,
                                   stepX: number, stepY: number, max: number): number {
  if (!editor || clientX < 0 || clientY < 0 || Math.abs(offsetX) > max || Math.abs(offsetY) > max) {
    return -1;
  }
  const posAtCords = editor.view.posAtCoords({left: clientX + offsetX, top: clientY + offsetY});
  if (!posAtCords) {
    return -1;
  }
  const resolvedPos = editor.state.doc.resolve(posAtCords.pos);
  const foundType = resolvedPos.node().type.name;
  if (foundType === 'textBlockNode') {
    return posAtCords.pos;
  }
  return findNextTextblockPosition(editor, clientX, clientY, offsetX + stepX, offsetY + stepY, stepX, stepY, max);
}

function findNextTextblockPositionToLeft(editor: Editor, clientX: number, clientY: number, step: number, max: number): number {
  return findNextTextblockPosition(editor, clientX, clientY, 0, 0, -step, 0, max);
}

function findNextTextblockPositionToRight(editor: Editor, clientX: number, clientY: number, step: number, max: number): number {
  return findNextTextblockPosition(editor, clientX, clientY, 0, 0, step, 0, max);
}

function findNextTextblockPositionUpwards(editor: Editor, clientX: number, clientY: number, step: number, max: number): number {
  return findNextTextblockPosition(editor, clientX, clientY, 0, 0, 0, -step, max);
}

function findNextTextblockPositionDownwards(editor: Editor, clientX: number, clientY: number, step: number, max: number): number {
  return findNextTextblockPosition(editor, clientX, clientY, 0, 0, 0, step, max);
}

/**
 * Finds the next textblock in the surrounding of a cords based position
 * @param clientX starting position X (compatible to mouse event clientX)
 * @param clientY starting position Y (compatible to mouse event clientY)
 * @return The document position of the first found textblock, -1 if no block is found
 */
export function findNextTextblockPositionByCords(editor: Editor, clientX: number, clientY: number): number {
  // first look to the left
  let found = findNextTextblockPositionToLeft(editor, clientX, clientY, 3, 500);
  if (found > 0) {
    return found;
  }
  // if not found  look to the right
  found = findNextTextblockPositionToRight(editor, clientX, clientY, 3, 50);
  if (found > 0) {
    return found;
  }
  // look upwards
  found = findNextTextblockPositionDownwards(editor, clientX, clientY, 6, 100);
  if (found > 0) {
    return found;
  }
  // look downwards
  return findNextTextblockPositionUpwards(editor, clientX, clientY, 6, 100);
}